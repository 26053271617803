import React from 'react';
import { graphql } from 'gatsby';
import { ListThumb } from '../components/Post/PostList';
import CategoryNav from '../components/Category/CategoryNav';
import Pagination from '../components/Pagination/Pagination';
import SEO from '../components/SEO';
import Container from '../components/Layout/Container';

export default function Category({ data, pageContext }) {
  const { recipes } = data;

  const pageSize = parseInt(process.env.GATSBY_PAGE_SIZE, 10);
  const currentPage = pageContext.currentPage || 1;

  return (
    <section>
      <SEO title={`Recetas de ${pageContext.slug.replace(/-/gi, ' ')}`} />
      <Container>
        <CategoryNav activeFilter={pageContext.slug} />
        <ListThumb recipes={recipes.nodes} />
        <Pagination
          pageSize={pageSize}
          totalCount={recipes.totalCount}
          currentPage={currentPage}
          base={`recetas/${pageContext.slug}`}
        />
      </Container>

    </section>
  );
}

export const query = graphql`
  query($slug: [String]!, $skip: Int = 0, $pageSize: Int = 10) {
    recipes: allRecipesJson(
      filter: { categories: { in: $slug } }
      sort: { fields: created_at, order: DESC }
      skip: $skip
      limit: $pageSize
    ) {
      totalCount
      nodes {
        slug
        title
        price
        people
        time
        description
        categories
        created_at
        images {
          name
          title
          description
          type
          order
        }
      }
    }
    categories: allCategoriesJson {
      nodes {
        slug
        name
      }
    }
  }
`;

import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Container from '../Layout/Container';

const StyledPagination = styled.nav`
  display: flex;
  align-content: space-between;
  align-items: center;
  justify-items: center;
  border: 1px solid var(--color-divider);
  margin: 1.5rem 0;
  border-radius: 5px;
  text-align: center;

  a {
    text-transform: uppercase;
    }
  }

  & > * {
    padding: 1rem 2rem;
    flex: 1;
    border-right: 1px solid var(--color-divider);
    text-decoration: none;

    &[aria-current],
    &.current {
      background: var(--color-secondary);
      color: var(--color-fg-white);
    }

    &[disabled] {
      pointer-events: none;
      color: var(--color-divider);
    }
  }
`;

export default function Pagination({
  pageSize,
  totalCount,
  currentPage,
  base,
}) {
  const totalPages = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= totalPages;
  const hasPrevPage = prevPage >= 1;

  return (
    <Container>
      <StyledPagination>
        <Link disabled={!hasPrevPage} to={`/${base}/${prevPage}`}>
          Anterior
        </Link>
        <Link disabled={!hasNextPage} to={`/${base}/${nextPage}`}>
          Siguiente
        </Link>
      </StyledPagination>
    </Container>
  );
}
